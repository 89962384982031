.vacancies-home {
  width: 100%;
  display: grid;
  justify-content: center;
  background-color: #66cdbd;
  @media (min-width: 768px) {
    background-color: unset;
  }
}

.vacancies-home__container {
  width: 320px;
  margin-left: 10px;
  padding-top: 68px;
  padding-bottom: 64px;
  @media (min-width: 768px) {
    width: 768px;
    margin: 0;
    padding-left: 64px;
    background-color: #66cdbd;
    background-image: url(../../../assets/images/Home/vacancies-home768.webp);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;

    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Home/vacancies-home768@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Home/vacancies-home768@3x.webp);
    }
  }
  @media (min-width: 1440px) {
    width: 1392px;
    background-image: url(../../../assets/images/Home/vacancies-home.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Home/vacancies-home@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Home/vacancies-home@3x.webp);
    }
  }
}

.vacancies-home__title {
  width: 309px;
  font-family: "Nexa Bold";
  font-size: 36px;
  font-weight: normal;
  line-height: 1.19;
  color: #253746;
  @media (min-width: 768px) {
    width: 100%;
  }
}

.vacancies-home__content {
  @media (min-width: 768px) {
    width: 445px;
  }
}

.vacancies-home__list {
  margin-top: -2px;
}

.vacancies-home__list-title {
  margin-top: 30px;
  font-family: "Nexa Bold";
  font-size: 18px;
  line-height: 27px;
  color: #253746;
  @media (min-width: 768px) {
  }
}

.vacancies-home__button {
  width: max-content;
  display: inline-block;
  margin-top: 12px;
  margin-right: 12px;
  text-decoration: none;
  padding: 9px 16px;
  border-radius: 37px;
  color: #ffffff;
  border: solid 1px #ffffff;
  font-family: "Nexa Bold";
  font-size: 14px;
  line-height: 15px;
}
