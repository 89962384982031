.apply-button {
  width: max-content;
  padding: 23px 48px;
  border-radius: 37px;
  font-family: "Nexa Bold";
  font-size: 14px;
  line-height: 18px;
  color: #253746;
  background-color: #ffb81c;
  cursor: pointer;
  justify-self: center;
}

.apply-button__top {
  display: block;
  @extend .apply-button;

  margin: 32px 0;

  @media (min-width: 768px) {
    margin: 0;
  }

  @media (min-width: 1440px) {
    justify-self: auto;
    // position: absolute;
    bottom: 0px;
    left: 125px;
  }
}

.apply-button__bottom {
  display: block;
  @extend .apply-button;

  margin-top: 32px;

  @media (min-width: 768px) {
    margin: 0;
  }
}
