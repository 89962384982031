.experience {
  width: 100%;
  display: grid;
  justify-content: center;
  background-color: #ffc649;
  @media (min-width: 768px) {
    background-color: unset;
  }
}

.experience__container {
  width: 322px;
  padding-top: 55px;
  padding-bottom: 61px;
  display: grid;
  grid-row-gap: 25px;
  background-color: #ffc649;

  @media (min-width: 768px) {
    width: 768px;
    grid-row-gap: 22px;
    padding-top: 57px;
    padding-left: 64px;
    padding-bottom: 64px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;

    &--net-developer {
      background-image: url(../../../assets/images/Vacancies/net-developer/code768.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/net-developer/code768@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/net-developer/code768@3x.webp);
      }
    }

    &--engineer-developer {
      background-image: url(../../../assets/images/Vacancies/net-developer/code768.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/net-developer/code768@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/net-developer/code768@3x.webp);
      }
    }

    &--java-developer {
      background-image: url(../../../assets/images/Vacancies/java-developer/code768.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/java-developer/code768@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/java-developer/code768@3x.webp);
      }
    }

    &--creatio-low-coder {
      background-image: url(../../../assets/images/Vacancies/creatio-low-coder/code768.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/creatio-low-coder/code768@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/creatio-low-coder/code768@3x.webp);
      }
    }

    &--android-developer {
      background-image: url(../../../assets/images/Vacancies/android-developer/code768.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/android-developer/code768@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/android-developer/code768@3x.webp);
      }
    }

    &--dev-ops-engineer {
      background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/code768.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/code768@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/code768@3x.webp);
      }
    }

    &--ios-developer {
      background-image: url(../../../assets/images/Vacancies/ios-developer/code768.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/ios-developer/code768@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/code768@3x.webp);
      }
    }

    &--qa-automation-engineer {
      background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/code768.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/code768@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/code768@3x.webp);
      }
    }

    &--senior-designer {
      background-image: url(../../../assets/images/Vacancies/senior-designer/code768.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/senior-designer/code768@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/senior-designer/code768@3x.webp);
      }
    }

    &--web-developer {
      background-image: url(../../../assets/images/Vacancies/web-developer/code768.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/web-developer/code768@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/web-developer/code768@3x.webp);
      }
    }
  }
  
  @media (min-width: 1440px) {
    width: 1392px;
    padding-top: 54px;
    padding-bottom: 73px;
    background-size: contain;
    &--net-developer {
      background-image: url(../../../assets/images/Vacancies/net-developer/code.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/net-developer/code@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/net-developer/code@3x.webp);
      }
    }
    &--engineer-developer {
      background-image: url(../../../assets/images/Vacancies/net-developer/code.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/net-developer/code@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/net-developer/code@3x.webp);
      }
      background-size: 50% 100%;
    }
    &--java-developer {
      background-image: url(../../../assets/images/Vacancies/java-developer/code.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/java-developer/code@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/java-developer/code@3x.webp);
      }
    }

    &--creatio-low-coder {
      background-image: url(../../../assets/images/Vacancies/creatio-low-coder/code.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/creatio-low-coder/code@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/creatio-low-coder/code@3x.webp);
      }
    }

    &--android-developer {
      background-image: url(../../../assets/images/Vacancies/android-developer/code.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/android-developer/code@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/android-developer/code@3x.webp);
      }
    }

    &--dev-ops-engineer {
      background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/code.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/code@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/code@3x.webp);
      }
    }

    &--ios-developer {
      background-image: url(../../../assets/images/Vacancies/ios-developer/code.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/ios-developer/code@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/code@3x.webp);
      }
    }

    &--qa-automation-engineer {
      background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/code.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/code@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/code@3x.webp);
      }
    }

    &--senior-designer {
      background-image: url(../../../assets/images/Vacancies/senior-designer/code.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/senior-designer/code@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/senior-designer/code@3x.webp);
      }
    }

    &--web-developer {
      background-image: url(../../../assets/images/Vacancies/web-developer/code.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/web-developer/code@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/web-developer/code@3x.webp);
      }
    }
  }
}

.experience__text {
  margin-left: 6px;
  font-family: "Nexa Regular";
  font-size: 18px;
  line-height: 1.5;
  color: #253746;
  @media (min-width: 768px) {
    width: 429px;
    font-size: 24px;
    margin: 0;
  }
}

.experience__list {
  margin-left: 22px;
  display: grid;
  grid-row-gap: 27px;
  @media (min-width: 768px) {
    margin-left: 0;
  }
}

.experience__list-item {
  display: grid;
  grid-template-columns: 15px 263px;
  grid-column-gap: 10px;
  align-items: center;
  font-family: "Nexa Regular";
  font-size: 18px;
  line-height: 1.5;
  color: #253746;
  @media (min-width: 768px) {
    grid-template-columns: 15px 423px;
  }
}
