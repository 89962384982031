.video {
  width: 100%;
  margin: auto;

  @media (min-width: 768px) {
    width: 768px;
  }
  @media (min-width: 1440px) {
    width: 1392px;
  }

  /* Removes shadow border from YouTube play button intoduced as default by react-lite-youtube-embed */
  button {
    border: 0;
  }
}
