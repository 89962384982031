.description {
  width: 100%;
  min-height: 560px;
  display: grid;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;

  &--net-developer {
    background-position: 77%;
    background-image: url(../../../assets/images/Vacancies/net-developer/net-developer.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/net-developer/net-developer@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/net-developer/net-developer@3x.webp);
    }
  }

  &--java-developer {
    background-position: center;
    background-image: url(../../../assets/images/Vacancies/java-developer/java-developerbg.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/java-developer/java-developerbg@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/java-developer/java-developerbg@3x.webp);
    }
  }

  &--creatio-low-coder {
    background-position: left;
    background-image: url(../../../assets/images/Vacancies/creatio-low-coder/creatio-low-coderbg.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/creatio-low-coder/creatio-low-coderbg@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/creatio-low-coder/creatio-low-coderbg@3x.webp);
    }
  }

  &--qa-automation-engineer {
    background-position: 25%;
    background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/qa-automation-engineerbg.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/qa-automation-engineerbg@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/qa-automation-engineerbg@3x.webp);
    }
  }

  &--web-developer {
    background-position: right;
    background-image: url(../../../assets/images/Vacancies/web-developer/web-developerbg.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/web-developer/web-developerbg@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/web-developer/web-developerbg@3x.webp);
    }
  }

  &--ios-developer {
    background-position: 42%;
    background-image: url(../../../assets/images/Vacancies/ios-developer/ios-developerbg.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/ios-developer/ios-developerbg@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/ios-developer/ios-developerbg@3x.webp);
    }
  }

  &--android-developer {
    background-position: 20%;
    background-image: url(../../../assets/images/Vacancies/android-developer/android-developerbg.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/android-developer/android-developerbg@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/android-developer/android-developerbg@3x.webp);
    }
  }

  &--dev-ops-engineer {
    background-position: 74%;
    background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/dev-ops-engineerbg.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/dev-ops-engineerbg@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/dev-ops-engineerbg@3x.webp);
    }
  }

  &--senior-designer {
    background-position: left;
    background-image: url(../../../assets/images/Vacancies/senior-designer/senior-designerbg.webp);
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url(../../../assets/images/Vacancies/senior-designer/senior-designerbg@2x.webp);
    }
    @media (-webkit-min-device-pixel-ratio: 3) {
      background-image: url(../../../assets/images/Vacancies/senior-designer/senior-designerbg@3x.webp);
    }
  }

  @media (min-width: 768px) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
  }
  @media (min-width: 1440px) {
    height: 560px;
    background-image: none;
  }
}

.description__container {
  max-width: 280px;
  margin-right: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 51px;

  @media (min-width: 768px) {
    max-width: unset;
    width: 768px;
    grid-template-columns: 89px 415px;
    grid-column-gap: 148px;
    grid-row-gap: 24px;
    padding-left: 64px;
    margin: 0;
    padding-bottom: 50px;
  }
  @media (min-width: 1440px) {
    width: 1392px;
    padding-left: 125px;
    padding-bottom: 24px;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 800px;
    grid-template-columns: unset;
    position: relative;
    grid-row-gap: 0px;

    &--net-developer {
      background-image: url(../../../assets/images/Vacancies/net-developer/net-developer1440.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/net-developer/net-developer1440@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/net-developer/net-developer1440@3x.webp);
      }
    }

    &--java-developer {
      background-image: url(../../../assets/images/Vacancies/java-developer/java-developer.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/java-developer/java-developer@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/java-developer/java-developer@3x.webp);
      }
    }

    &--creatio-low-coder {
      background-image: url(../../../assets/images/Vacancies/creatio-low-coder/creatio-low-coder.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/creatio-low-coder/creatio-low-coder@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/creatio-low-coder/creatio-low-coder@3x.webp);
      }
    }

    &--qa-automation-engineer {
      background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/qa-automation-engineer.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/qa-automation-engineer@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/qa-automation-engineer/qa-automation-engineer@3x.webp);
      }
    }

    &--web-developer {
      background-image: url(../../../assets/images/Vacancies/web-developer/web-developer.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/web-developer/web-developer@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/web-developer/web-developer@3x.webp);
      }
    }

    &--ios-developer {
      background-image: url(../../../assets/images/Vacancies/ios-developer/ios-developer.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/ios-developer/ios-developer@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/ios-developer/ios-developer@3x.webp);
      }
    }

    &--android-developer {
      background-image: url(../../../assets/images/Vacancies/android-developer/android-developer.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/android-developer/android-developer@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/android-developer/android-developer@3x.webp);
      }
    }

    &--dev-ops-engineer {
      background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/dev-ops-engineer.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/dev-ops-engineer@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/dev-ops-engineer/dev-ops-engineer@3x.webp);
      }
    }

    &--senior-designer {
      background-image: url(../../../assets/images/Vacancies/senior-designer/senior-designer.webp);
      @media (-webkit-min-device-pixel-ratio: 2) {
        background-image: url(../../../assets/images/Vacancies/senior-designer/senior-designer@2x.webp);
      }
      @media (-webkit-min-device-pixel-ratio: 3) {
        background-image: url(../../../assets/images/Vacancies/senior-designer/senior-designer@3x.webp);
      }
    }
  }
}

.description__button {
  text-decoration: none;
  width: max-content;
  height: max-content;
  display: grid;
  grid-template-columns: 5px 35px;
  grid-column-gap: 9px;
  align-content: center;
  padding: 9px 20px;
  border-radius: 37px;
  font-family: "Nexa Bold";
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  background-color: #253746;
}

.description__button__arrow {
  font-size: 10px;
}

.description__title {
  font-family: "Nexa Bold";
  font-size: 36px;
  font-weight: normal;
  line-height: 55px;
  color: #253746;

  @media (min-width: 768px) {
    margin-top: 89px;
    font-size: 56px;
    line-height: 68px;
  }
  @media (min-width: 1440px) {
    max-width: 440px;
    margin-top: 32px;
  }
}

.text-wrapper {
  display: block !important;
  margin-top: -32px;
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 27px;
  font-family: "Nexa Regular";
  font-size: 18px;
  line-height: 1.5;
  color: #4f616c;

  @media (min-width: 768px) {
    grid-column: 2;
    margin-top: 0;
  }
  @media (min-width: 1440px) {
    grid-column: unset;
    width: 440px;
    margin-top: 32px;
  }
}
